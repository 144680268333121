<template>
  <div class="elderlyCareAppointmentApply">
    <div class="section-form">
      <div class="section-item">
        <div class="section-item--label">预约房号：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="roomName"
            disabled
            inputAlign="right"
            placeholder="请填写预约房号"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">预约床位号：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="bedName"
            disabled
            inputAlign="right"
            placeholder="请填写预约床位号"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">姓名：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.elderlyName"
            inputAlign="right"
            placeholder="请填写姓名"
          ></v-input>
        </div>
      </div>

      <div class="section-item radio">
        <div class="section-item--label">性别：</div>
        <div class="section-item--control">
          <v-radio-group
            :radioObj="sexOps"
            :value.sync="form.elderlySex"
          ></v-radio-group>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">出生日期：</div>
        <div class="section-item--control" @click="dateTimeShow = true">
          <v-input
            v-model.trim="form.birthDate"
            disabled
            inputAlign="right"
            placeholder="请填写出生日期"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">年龄：</div>
        <div class="section-item--control">
          <v-input
            type="number"
            v-model.trim="form.elderlyAge"
            :maxlength="3"
            inputAlign="right"
            placeholder="请填写年龄"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">手机号：</div>
        <div class="section-item--control">
          <v-input
            type="number"
            :maxlength="11"
            v-model.trim="form.elderlyMobile"
            inputAlign="right"
            placeholder="请填写手机号"
          ></v-input>
        </div>
      </div>

      <div class="surebtn">
        <div class="btn" @click="submit">立即预订</div>
      </div>
    </div>

    <v-dateTimePicker
      :value="dateTime"
      :valueShow.sync="dateTimeShow"
      :isAuto="true"
      type="date"
      title="选择完整时间"
      @confirmPicker="confirmDate"
    ></v-dateTimePicker>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { addInfoURL, submitOrderURL, userInfoURL } from "./api";

export default {
  name: "elderlyCareAppointmentApply",
  data() {
    return {
      sexOps: [
        { name: 1, value: "男" },
        { name: 2, value: "女" },
      ],
      form: {
        roomId: "",
        birthDate: "",
        elderlyName: "",
        elderlySex: 1,
        elderlyAge: "",
        elderlyMobile: "",
      },
      roomName: "",
      bedName: "",
      isClick: true,
      /* v-dateTimePicker */
      dateTime: new Date(),
      dateTimeShow: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const { id, roomName, bedName } = this.$route.query;
      this.id = id;
      this.form.roomId = id;
      this.roomName = roomName;
      this.bedName = bedName;
    },
    async submit() {
      if (!this.valid()) {
        return;
      }
      if (!this.isClick) {
        return;
      }
      this.isClick = false;
      let res = await this.$axios.post(submitOrderURL, {
        ...this.form,
        // houseId: this.houseId || this.communityId || undefined,
        userId: this.userId,
      });
      if (res.code === 200 && res.success) {
        this.$toast({
          message: "提交申请成功",
          forbidClick: true,
          duration: 3000,
        });
        this.$router.replace({
          name: "elderlyCareAppointmentComplete",
        });
        return;
      } else {
        this.$toast(res.msg || "操作失败，请稍后重试！");
      }
      this.isClick = true;
    },
    // 验证必填项
    valid() {
      const {
        // roomName,
        // bedName,
        birthDate,
        elderlyName,
        elderlySex,
        elderlyAge,
        elderlyMobile,
      } = this.form;
      let message = undefined;
      // if (roomName === "") {
      //   message = "预约房号不可为空";
      // } else if (bedName === "") {
      //   message = "预约床位号不可为空";
      // } else
      if (elderlyName === "") {
        message = "姓名不可为空";
      } else if (elderlySex === "" || elderlySex === undefined) {
        message = "性别不可为空";
      } else if (birthDate === "" || birthDate === undefined) {
        message = "出生日期不可为空";
      } else if (elderlyAge === "" || elderlyAge === undefined) {
        message = "年龄不可为空";
      } else if (elderlyMobile === "" || elderlyMobile === undefined) {
        message = "手机号不可为空";
      }
      if (message) {
        this.$toast({
          message,
          duration: 2500,
        });
      }
      return !message;
    },

    // 选择日期
    confirmDate(val) {
      this.form.birthDate = moment(val).format("YYYY-MM-DD");
      this.form.elderlyAge = String(this.getAge(val));
    },
    // 计算年龄
    getAge(birthday) {
      //出生时间 毫秒
      var birthDayTime = new Date(birthday).getTime();
      //当前时间 毫秒
      var nowTime = new Date().getTime();
      //一年毫秒数(365 * 86400000 = 31536000000)
      return Math.floor((nowTime - birthDayTime) / 31536000000);
    },
  },
};
</script>
<style lang="less" scoped>
.elderlyCareAppointmentApply {
  font-family: PingFangSC-Regular, PingFang SC;
  box-sizing: border-box;
  // background: #f6f8fb;
  // min-height: 100%;
  height: 100%;
  width: 100%;
  padding: 30px 50px;
  padding-bottom: calc(
    30px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    30px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    .section-item {
      display: flex;
      border-bottom: 4px solid rgba(0, 0, 0, 0.04);
      .section-item--label {
        flex: 1;
        line-height: 74px;
        color: #333333;
        font-size: 52px;
        text-align: left;
        font-weight: 400;
        white-space: nowrap;
        padding: 36px 0 30px;
      }
      .section-item--control {
        max-width: 400px;
        display: flex;
        align-items: center;
        ::v-deep .van-cell {
          font-size: 42px;
          .van-field__control {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
    .surebtn {
      flex: 1;
      display: flex;
      align-items: flex-end;
      padding: 78px 0 20px;
      width: 100%;
      .btn {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 492px;
        height: 94px;
        background: #7cc0a3;
        // background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
        border-radius: 16px;
        // border: 8px solid #ecf7ff;
        font-weight: bold;
        font-size: 46px;
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }
}
</style>
