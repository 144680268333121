let origin = window.ORIGIN;
// 床位列表
const bedListPagingURL = `${origin}/gateway/hc-health/miniapi/elderlyCare/bedListPaging`;
// 床位详情
const bedDetailByIdURL = `${origin}/gateway/hc-health/miniapi/elderlyCare/bedDetailById`;
// 提交床位预约订单申请
const submitOrderURL = `${origin}/gateway/hc-health/miniapi/elderlyCare/submitOrder`;

// 订单列表
const orderListPagingURL = `${origin}/gateway/hc-health/miniapi/elderlyCare/orderListPaging`;
// 订单详情
const orderDetailByIdURL = `${origin}/gateway/hc-health/miniapi/elderlyCare/orderDetailById`;
// 取消订单
const cancelOrderURL = `${origin}/gateway/hc-health/miniapi/elderlyCare/cancelOrder`;
// 订单审批
const orderAuditURL = `${origin}/gateway/hc-health/miniapi/elderlyCare/orderAudit`;
// 订单审批
const getFunctionSettingUrl = `${origin}/gateway/blade-system/application/getFunctionSetting`;

export {
  getFunctionSettingUrl,
  bedListPagingURL,
  bedDetailByIdURL,
  submitOrderURL,
  orderListPagingURL,
  orderDetailByIdURL,
  cancelOrderURL,
  orderAuditURL,
};
